import * as React from 'react';
import { Link } from '@reach/router';

import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';

interface CTAProps {
  text: string;
  path?: string;
}

function CTA({ text, path }: CTAProps): React.ReactElement {
  const config = useSanitySiteSettings();
  return (
    <div className="overflow-hidden bg-pink">
      <div className="relative w-full px-4 py-12 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="text-3xl font-semibold text-center text-white">
          {text}
          <span> </span>
          {text === 'Want to know more about our cosmetic tattoo services?' && (
            <br />
          )}
          {path ? (
            <Link to={path} className="border-b-2 border-white">
              Click here
            </Link>
          ) : (
            <a
              href={`tel:${config.phoneNumber}`}
              className="border-b-2 border-white"
            >
              Click here
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export { CTA };
